import { Site, TMenu } from '@app/model';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

export type ExpandLayout = {
    header?: React.ReactNode;
    footer?: React.ReactNode;
    layoutProps?: {
        wrapperClassName?: string;
        menuData?: TMenu[];
        generalData?: Site;
    };
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>;

export const ExpandLayout = ({
    className,
    children,
    layoutProps: { wrapperClassName } = {},
    ...rest
}: ExpandLayout) => {
    const router = useRouter();
    return (
        <div
            className={classnames('content-wrapper', wrapperClassName)}
            {...rest}
        >
            <header className="wrapper bg-light expand-header">
                <div className="bg-primary text-white fw-bold fs-15 top-bar">
                    <div className="container-fluid d-md-flex flex-md-row mx-lg-0 mx-xl-auto py-1">
                        <div className="d-md-flex flex-lg-row flex-wrap">
                            <div className="d-flex flex-row align-items-center me-6 mb-1 mb-md-0">
                                <address className="mb-0">
                                    <a className="link-white hover d-flex align-items-center link-with-icon"
                                        href="#" onClick={() => router.back()}
                                    >
                                        <i className="uil uil-angle-left me-1 base-icon fs-22" />
                                        Back to Van Lang IPA
                                    </a>
                                </address>
                            </div>
                        </div>
                        <div className="d-md-flex flex-lg-row flex-wrap ms-auto">
                            <div className="d-flex flex-row align-items-center ms-md-4 ms-lg-6 mb-1 mb-md-0">
                                <p className="mb-0">
                                    <a
                                        href="https://vanlangipa.zixportal.com/s/login?b=vanlangipa"
                                        className="link-white hover link-with-icon"
                                        target='_blank'
                                        rel="noreferrer"
                                    >
                                        <i className="uil uil-fast-mail-alt me-1 base-icon fs-22" />
                                        Secure Mail
                                    </a>
                                </p>
                            </div>
                            {/* <div className="d-flex flex-row align-items-center ms-md-4 ms-lg-6 ">
                                <p className="mb-0">
                                    <a
                                        href="https://provider.tecqpartners.com"
                                        className="link-white hover link-with-icon"
                                        target='_blank'
                                        rel="noreferrer"
                                    >
                                        <i className="uil uil-external-link-alt base-icon me-1" />
                                Provider Login
                                    </a>
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </header>
            {/* <!-- main --> */}
            <main className={classnames(' bg-light', className)}>
                {children}
            </main>
        </div>
    );
};
