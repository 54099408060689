/**
 * https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
 * @param color Color for grading
 * @param amount Grading percentage (range -100 - 100), 
 */
export const colorGrading = (color: string | null | undefined, amount: number) => {
    if (typeof color !== 'string') return undefined;
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).slice(-2));
};

export const textColorDetect = (backgroundColor: undefined | null | string) => {
    if (typeof backgroundColor !== 'string') return '#fff';
    function hexToRgb(hex: string) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? [
                `${parseInt(result[1], 16)}`,
                `${parseInt(result[2], 16)}`,
                `${parseInt(result[3], 16)}`,
            ] : ['255', '255', '255'];
    }
    const rgb = hexToRgb(backgroundColor);
    var o = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) /1000);
    
    if(o > 125) {
        return '#000000';
    }else{ 
        return '#ffffff';
    }
};

export const createCustomVariableSheet = (primaryColor?: string | null, secondaryColor?: string | null) => {
    try {
        const normalizedSecondaryColor = secondaryColor ?? primaryColor;
        const colorSheet: Record<string, string | undefined | null> = {
            '--primary': primaryColor,
            '--primaryLight': colorGrading(primaryColor, 25),
            '--primaryDark': colorGrading(primaryColor, -25),
            '--textOnPrimary': textColorDetect(primaryColor),
            '--secondary': normalizedSecondaryColor,
            '--secondaryLight': colorGrading(normalizedSecondaryColor, 25),
            '--secondaryDark': colorGrading(normalizedSecondaryColor, -25),
            '--textOnSecondary': textColorDetect(normalizedSecondaryColor),
        };

        return Object
            .entries(colorSheet)
            .filter(([, value]) => typeof value === 'string')
            .map(([property, value]) => `${property}: ${value};`).join('\n');
    } catch (e) {
        return '';
    }
};