import { TMenu } from '@app/model';
import React from 'react';
import { NavbarLayout } from '../navbar';
import { TopBar } from '../navbar/topbar';

type HeaderLayoutType = {
    menuData?: TMenu[];
} & Pick<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
    'children'
>;

export const HeaderLayout = ({ menuData = [] }: HeaderLayoutType) => {
    return (
        <header
            className="wrapper bg-dark position-sticky header-navbar"
            id="header"
        >
            <TopBar />
            <NavbarLayout {...{ menuData }} />
        </header>
    );
};
