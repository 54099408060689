import Link from 'next/link';
import React from 'react';

export const TopBar = () => {
    return (
        <div className="bg-primary text-white fw-bold fs-14 top-bar" >
            <div className="container-xl d-flex flex-row mx-lg-0 mx-xl-auto py-1">
                <div className="d-md-flex flex-lg-row flex-wrap">
                    <div className="d-flex flex-row align-items-center me-6">
                        <address className="mb-0 d-flex align-items-center">
                            <Link href={'https://www.tecqpartners.com/'}>
                                <a className='text-white' target={'_blank'}>Managed by TECQ Partners</a>
                            </Link>
                        </address>
                    </div>
                </div>
                <div className="d-md-flex flex-lg-row justify-content-end flex-wrap ms-auto">
                    <div className="d-flex flex-row align-items-center ms-6 py-1 py-lg-0">
                        <p className="mb-0">
                            <a
                                href="https://vanlangipa.zixportal.com/s/login?b=vanlangipa"
                                className="link-white hover link-with-icon"
                                target='_blank'
                                rel="noreferrer"
                            >
                                <i className="uil uil-fast-mail-alt me-1 base-icon fs-22" />
                                Secure Mail
                            </a>
                        </p>
                    </div>
                    {/* <div className="d-flex flex-row align-items-center ms-6 py-1 py-lg-0">
                        <p className="mb-0">
                            <a
                                href="https://provider.tecqpartners.com/"
                                className="link-white hover link-with-icon"
                                target='_blank'
                                rel="noreferrer"
                            >
                                <i className="uil uil-external-link-alt base-icon me-1" />
                                Provider Login
                            </a>
                        </p>
                    </div> */}
                </div>
            </div>
        </div>
    );
};
