import { CMS_ENTRY_STATUS_PUBLISHED } from '@app/constants';
import { TMenu } from '@app/model';
import React from 'react';

export type MenuItem = {
    title?: string;
    link?: string;
    subMenu?: any;
    nestedItemsData?: {
        title: string;
        link: string;
    }[];
    isMegaMenu?: boolean;
    menuItem?: any;
    tooltip?: string | null;
    openInNewTab?: boolean | null;
};

const MenuItem = ({
    title,
    link,
    subMenu = [],
    nestedItemsData = [],
    isMegaMenu,
    menuItem,
    tooltip,
    openInNewTab,
}: MenuItem) => {
    if (isMegaMenu) {
        if (!subMenu || subMenu?.length === 0) {
            return (
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href={'#'}>
                        {title}
                    </a>
                    <NormalSubMenu
                        items={subMenu}
                        nestedItems={nestedItemsData}
                    />
                </li>
            );
        }

        return (
            <li className="nav-item">
                <a className="nav-link" href={'#'}>
                    {title}
                </a>
                <MegaSubMenu items={subMenu} nestedItems={nestedItemsData} menuItem={menuItem} />
            </li>
        );
    }

    if (subMenu?.length > 0 || nestedItemsData.length > 0) {
        return (
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href={'#'}>
                    {title}
                </a>
                <NormalSubMenu items={subMenu} nestedItems={nestedItemsData} />
            </li>
        );
    }

    // Only show tooltip on memu item that doesn't have submenu.
    const tooltipProps = tooltip
        ? {
            'data-bs-toggle': 'popover',
            'data-bs-placement': 'bottom',
            'data-bs-trigger': 'hover',
            'data-bs-content': tooltip
        }
        : {};
    const openNewTabProps = openInNewTab
        ? {
            target: '_blank',
            rel: 'noreferrer'
        }
        : {};

    return (
        <li className="nav-item">
            <a
                className="nav-link"
                href={link}
                {...tooltipProps}
                {...openNewTabProps}
            >
                {title}
            </a>
        </li>
    );
};

const DropdownMenuItem = ({
    title,
    link,
    subMenu = [],
    nestedItemsData = [],
}: { title?: string; link?: string; subMenu?: any; nestedItemsData?: any }) => {
    if (subMenu?.length > 0) {
        return (
            <li className="dropdown dropdown-menu-item">
                <a className="py-2 dropdown-item dropdown-toggle" href={link}>
                    {title}
                </a>
                <NormalSubMenu items={subMenu} nestedItems={nestedItemsData} />
            </li>
        );
    }

    return (
        <li className="nav-item dropdown-menu-item">
            <a className="py-2 dropdown-item" href={link}>
                {title}
            </a>
        </li>
    );
};

export const MegaSubMenu = ({ items = [], nestedItems = [], menuItem }: { items: any; nestedItems: any; menuItem: any }) => {
    let columns: any[] = [];
    let posts: any[] = [];
    let videos: any[] = [];
    let pages: any[] = [];

    items?.map((item: any) => {
        if (!item.is_menu) return;
        const {
            title,
            link,
            pages,
            category_template,
            slug,
            videos: itemVideos,
        } = item;

        const cat_template = category_template ?? 'category';

        const titleLink = `/${cat_template}/${slug}`;

        if (Array.isArray(pages) && pages.length > 0) {
            const pagesDataConvert = pages
                .sort((l, r) => (l.order ?? 0) - (r.order ?? 0))
                .filter(entry => entry.status === CMS_ENTRY_STATUS_PUBLISHED)
                .map(({ title, slug, link: internalLink, is_menu, template }: any) => ({
                    title,
                    link: internalLink ?? (`/page/${slug}` || link),
                    is_menu,
                    template
                }));
            const splitCount = 20;

            for (let cnt = 0; cnt < Math.ceil(pagesDataConvert.length / splitCount); cnt++) {
                const slicedColumn = pagesDataConvert.slice(cnt * splitCount, (cnt + 1) * splitCount);
                columns.push({
                    headerTitle: cnt === 0 ? title : `${title} (cont.)`,
                    titleLink,
                    listItems: slicedColumn,
                });
            }

            return;
        }

        if (Array.isArray(itemVideos) && itemVideos.length > 0) {
            const videoDataConvert = itemVideos.map(({ title, slug }: any) => ({
                title,
                link: `/video/${slug}` || '#',
            }));

            columns.push({
                headerTitle: title,
                titleLink,
                listItems: videoDataConvert,
            });

            return;
        }

        videos?.map((video: any) => {
            if (video.slug.includes('video')) {
                videos.push(item);
            }
        });

        pages?.map((page: any) => {
            if (page.slug.includes('page')) {
                pages.push(item);
            }
            if (page.slug.includes('video')) {
                videos.push(item);
            }
            if (page.slug.includes('post')) {
                posts.push(item);
            }
        });
    });

    nestedItems.map((itemNested: any) => {
        if (itemNested.link.includes('page')) {
            pages.push(itemNested);
        }
        if (itemNested.link.includes('video')) {
            videos.push(itemNested);
        }
        if (itemNested.link.includes('post')) {
            posts.push(itemNested);
        }
    });

    if (posts.length > 0) {
        columns.push({ headerTitle: 'Posts', listItems: posts });
    }
    if (pages.length > 0) {
        columns.push({ headerTitle: 'Pages', listItems: pages });
    }
    if (videos.length > 0) {
        columns.push({ headerTitle: 'Videos', listItems: videos });
    }

    const menuMasonrySort = (columns: { headerTitle: any; listItems: any[]; titleLink?: any }[]) => {
        let list = columns.sort((l, r) => l.listItems.length - r.listItems.length);

        /**
         * Sắp xếp thứ tự các category vào 3 cột, sao cho số lượng hàng của mỗi cột có chênh lệch ít nhất
         */
        let itemMasonryList: { headerTitle: any; listItems: any[]; titleLink?: any }[][] = [[], [], []];
        let itemMasonryCount = [0, 0, 0];
        for (let i = list.length - 1; i >= 0; i--) {
            const current = list[i];
            let minSum = 9999;
            let minColumnIndex = 0;
            for (let j = 0; j < itemMasonryCount.length; j++) {
                if (itemMasonryCount[j] < minSum) {
                    minSum = itemMasonryCount[j];
                    minColumnIndex = j;
                }
            }
            itemMasonryList[minColumnIndex].push(current);
            itemMasonryCount[minColumnIndex] += current.listItems.length + 1;
        }

        return [
            itemMasonryList[0],
            itemMasonryList[1],
            itemMasonryList[2],
        ];
    };

    const isHealthPlanMenu = menuItem.slug === 'health-plans';
    return (
        <ul className="dropdown-menu mega-menu">
            {isHealthPlanMenu && <h3 className='text-center text-primary mb-2 custom-header-title'>Medicare Advantage Plans</h3>}
            <li className="mega-menu-content container">
                <div className="row">
                    {menuMasonrySort(columns)
                        .map((masonryColumn, masonryIndex) => {
                            if (masonryColumn.length < 1) return null;

                            return (
                                <div className="masonry-column" key={masonryIndex}>
                                    {masonryColumn.map((column, index) => {
                                        let categoryPath: string = '';
                                        if (isHealthPlanMenu) {
                                            const getPath: any = (column.listItems.filter((x) => x.link.includes('about-us')).pop());
                                            if (getPath?.link) {
                                                categoryPath = getPath.link;
                                            } else {
                                                categoryPath = column.listItems[0].link;
                                            }
                                        }
                                        return <div key={index}
                                            className={index === masonryColumn.length - 1 ? "mega-menu-item" : "mega-menu-item mb-3"}
                                        >
                                            {menuItem.slug === 'health-plans'
                                                ? <a href={categoryPath} className='header-link' >
                                                    <h6 className="dropdown-header mb-1">
                                                        <div className="dropdown-header-title">
                                                            {column.headerTitle}
                                                        </div>
                                                    </h6>
                                                </a>
                                                : <span className='header-link' >
                                                    <h6 className="dropdown-header mb-1">
                                                        <div className="dropdown-header-title no-hover">
                                                            {column.headerTitle}
                                                        </div>
                                                    </h6>
                                                </span>}
                                            <div className="row gx-0">
                                                <div className="col-sm">
                                                    <ul className="list-unstyled">
                                                        {column.listItems.map((item: any, index: number) => {
                                                            return item.template !== 'health-plan'
                                                                ? (<li key={index}>
                                                                    <a className="dropdown-item" href={item.link}>
                                                                        {item.title}
                                                                    </a>
                                                                </li>)
                                                                : item.is_menu === true && (<li key={index}>
                                                                    <a className="dropdown-item" href={item.link}>
                                                                        {item.title}
                                                                    </a>
                                                                </li>);
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>;
                                    })}
                                </div>
                            );

                            // return (
                            //     <div className="mega-menu-item mb-3" key={index}>
                            //         <a href={column?.titleLink ?? '#'} className='header-link' >
                            //             <h6 className="dropdown-header mb-1">
                            //                 <div className="dropdown-header-title">
                            //                     {column.headerTitle}
                            //                 </div>
                            //             </h6>
                            //         </a>
                            //         <div className="row gx-0">
                            //             <div className="col-sm">
                            //                 <ul className="list-unstyled">
                            //                     {column.listItems.map((item: any, index: number) => {
                            //                         return (
                            //                             <li key={index}>
                            //                                 <a className="dropdown-item" href={item.link}>
                            //                                     {item.title}
                            //                                 </a>
                            //                             </li>
                            //                         );
                            //                     })}
                            //                 </ul>
                            //             </div>
                            //         </div>
                            //     </div>
                            // );
                        })}
                    {/* <div className="row">
                    {columns.map((column, index) => {
                        return (
                            <div className="col-4 pb-2" key={index}>
                                <a href={column?.titleLink?? '#'} className='header-link' >
                                    <h6 className="dropdown-header mb-1">
                                        <div className="dropdown-header-title">
                                            {column.headerTitle}
                                        </div>
                                    </h6>
                                </a>
                                <div className="row gx-0">
                                    <div className="col-sm">
                                        <ul className="list-unstyled">
                                            {column.listItems.map((item: any, index: number) => {
                                                return (
                                                    <li key={index}>
                                                        <a className="dropdown-item" href={item.link}>
                                                            {item.title}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div> */}
                </div>
            </li>
        </ul>
    );
};

export const NormalSubMenu = ({ items = [], nestedItems = [] }: { items: any; nestedItems: any }) => {
    return (
        <ul className="dropdown-menu">
            {items?.map((item: any, index: number) => {
                const { title, slug, pages } = item;

                // if(Array.isArray(pages) && pages.length > 0) {
                //     return (

                //     )
                // }

                return (
                    <DropdownMenuItem
                        key={index}
                        title={title}
                        link={`/category/${slug}`}
                        subMenu={pages}
                    />
                );
            })}

            {nestedItems?.map((itemNested: any, index: number) => {
                const { title: itemTitle, link: nestedLink } = itemNested;
                return (
                    <DropdownMenuItem
                        key={index}
                        title={itemTitle}
                        link={nestedLink}
                    />
                );
            })}
        </ul>
    );
};

export const NavBarMenu = ({ menuData = [] }: { menuData: TMenu[]; megaMenu: any }) => {
    if (menuData.length == 0) return null;

    return (
        <>
            {menuData.map((menuItem, index) => {
                if (!menuItem) return null;
                if (!menuItem.is_menu || menuItem.status !== CMS_ENTRY_STATUS_PUBLISHED) return null;

                const { title, link } = menuItem;

                // if item has a link, link it directly
                const cat_template = menuItem.category_template ?? 'category';
                const {
                    children = [],
                    posts = [],
                    pages = [],
                    videos = [],
                    category_template,
                    tooltip,
                    open_link_new_tab,
                } = menuItem;

                if (
                    children?.length > 0 ||
                    posts?.length > 0 ||
                    videos?.length > 0 ||
                    pages?.length > 0
                ) {
                    let $subMenu: { title: string; link: string }[] = [];
                    if (
                        Array.isArray(pages) &&
                        category_template !== 'videos'
                    ) {
                        pages.map(({ is_menu, title, slug, status }: any) => {
                            if (!is_menu || status !== CMS_ENTRY_STATUS_PUBLISHED) return;
                            $subMenu.push({ title, link: `/page/${slug}` });
                        });
                    }
                    if (Array.isArray(posts)) {
                        posts.map(({ is_menu, title, slug, status }: any) => {
                            if (!is_menu || status !== CMS_ENTRY_STATUS_PUBLISHED) return;
                            $subMenu.push({ title, link: `/post/${slug}` });
                        });
                    }
                    if (Array.isArray(videos)) {
                        videos.map(({ is_menu, title, slug, status }: any) => {
                            if (!is_menu || status !== CMS_ENTRY_STATUS_PUBLISHED) return;
                            $subMenu.push({ title, link: `/video/${slug}` });
                        });
                    }

                    const link =
                        menuItem.link != 'NULL' && menuItem?.link?.length
                            ? menuItem.link
                            : `/${cat_template}/${menuItem.slug}`;

                    return (
                        <MenuItem key={index}
                            link={link}
                            title={title}
                            subMenu={children}
                            nestedItemsData={$subMenu}
                            menuItem={menuItem}
                            isMegaMenu
                        />
                    );
                }

                return <MenuItem key={index} title={title} tooltip={tooltip} link={link ? link : '#'} menuItem={menuItem} openInNewTab={open_link_new_tab} />;
            })}
        </>
    );
};
