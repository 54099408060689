import { useAppData } from '@app/hooks';
import { Site, TMenu } from '@app/model';
import React from 'react';

type FooterLayoutType = Pick<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
    'children'
> & {
    generalData: Site;
};

export const FooterLayout = ({ generalData }: FooterLayoutType) => {
    const currentYear = new Date().getFullYear();

    const { metaData: { quickLinks = [] } = {} } = useAppData() as { metaData: { quickLinks: TMenu[] } };

    return (
        <footer
            className="text-inverse shadow py-8 py-md-14"
            id="footer-custom"
        >
            <div className="container">
                <div className="row gy-6 gy-lg-0">
                    <div className="col-6 col-sm-8 col-lg-3">
                        <div className="widget">
                            <h4 className="widget-title  mb-3 text-white ">
                                Contact Us
                            </h4>
                            <address className="">
                                {generalData?.office_address}
                                <br />
                                {generalData?.office_city},{' '}
                                {generalData?.office_state}{' '}
                                {generalData?.office_zip}
                                <br />
                                {generalData?.country}
                            </address>
                            <a
                                href={`mailto:${generalData?.email}`}
                                className="link-body hover"
                            >
                                {generalData?.email}
                            </a>
                            <br />{' '}
                            <a
                                className="hover"
                                href={`tel:${`${generalData?.phone_number}`}`}
                            >{`${generalData?.phone_number}`}</a>
                        </div>
                        <p className="mb-4 mt-4">
                            © {currentYear} <b>{generalData?.name}</b>.{' '}
                            <br className="d-none d-lg-block" />
                            All rights reserved.
                        </p>
                    </div>
                    <div className="col-6 col-sm-4 col-lg-3">
                        <div className="widget">
                            <h4 className="widget-title  mb-3 text-white ">
                                Read More
                            </h4>
                            <ul className="list-unstyled text-reset mb-0">
                                {quickLinks.map((link, index) => {
                                    const current_link = link?.link?.length
                                        ? link.link
                                        : `/category/${link.slug}`;
                                    if (current_link === '#') return null;
                                    return (
                                        <li key={index}>
                                            <a
                                                className="hover"
                                                href={current_link}
                                            >
                                                {link.title}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <iframe
                            className="shadow-sm rounded"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3465.489626644069!2d-95.53275727920474!3d29.705573291564612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c2efcb4d6e81%3A0xa5c041c46dc3820d!2s8278%20Bellaire%20Blvd%2C%20Houston%2C%20TX%2077036!5e0!3m2!1sen!2sus!4v1632340933275!5m2!1sen!2sus"
                            width="100%"
                            height="300"
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>
            </div>
        </footer>
    );
};
