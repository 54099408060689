import {
    AppScript, APP_DESCRIPTION,
    APP_NAME, SEO_URL, TWITTER_HANDLE,
    TWITTER_SITE
} from '@app/constants';
import { CMS } from '@app/@gotecq-cms/data-layer';
import { MainLayout } from '@app/components/layout';
import { ExpandLayout } from '@app/components/layout/expand';
import { AppProvider } from '@app/context';
import { gtagUtils } from '@app/helpers';
import { DefaultSeo } from 'next-seo';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import React, { ReactNode, useEffect } from 'react';
import type { NextPageWithLayout } from '@app/types';
import type { AppProps } from 'next/app';
import '../static/css/plugins.css';
import '../static/css/style.css';
import '@app/styles/index.scss';
import { TCategory, Site, TMenu } from '@app/model';

type AppInitialCustomProps = {
    generalData: Site;
    menuData: TCategory[];
    quickLinks: TMenu[];
};

type AppPropsWithLayout = AppProps & { Component: NextPageWithLayout } & AppInitialCustomProps;

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => {
    NProgress.done();
    window.scrollTo(0, 0);
});
Router.events.on('routeChangeError', () => NProgress.done());

const MyApp = ({ Component, ...rest }: AppPropsWithLayout) => {
    const { asPath, events } = useRouter();
    const { pageProps, generalData, menuData, quickLinks } = rest;
    const additionalProps = {
        site_data: generalData,
        menu_items: menuData,
        quick_links: quickLinks,
    };

    const getLayout = Component.getLayout
        ? (page: ReactNode) => page
        : (page: ReactNode) =>
            (asPath?.includes('/find-a-doctor') || asPath?.includes('/doctor/'))
                ? <ExpandLayout layoutProps={{ menuData, generalData }}>{page}</ExpandLayout>
                : <MainLayout layoutProps={{ menuData, generalData }}>{page}</MainLayout>;

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            gtagUtils.pageView(url);
        };
        events.on('routeChangeComplete', handleRouteChange);
        return () => {
            events.off('routeChangeComplete', handleRouteChange);
        };
    }, [events]);

    return (
        <>
            <AppScript />
            <DefaultSeo
                openGraph={{
                    type: 'website',
                    locale: 'en_US',
                    url: SEO_URL,
                    site_name: APP_NAME,
                    description: APP_DESCRIPTION,
                }}
                twitter={{
                    handle: TWITTER_HANDLE,
                    site: TWITTER_SITE,
                    cardType: 'summary_large_image',
                }}
            />
            <AppProvider
                value={{
                    generalData,
                    menuData,
                    metaData: {
                        quickLinks,
                    },
                }}
            >
                {getLayout(<Component {...pageProps} {...additionalProps} />)}
            </AppProvider>
        </ >
    );
};

MyApp.getInitialProps = async () => {
    const generalData = await CMS.getSiteInfo();
    const menuData = await CMS.getMenu();

    return {
        generalData,
        menuData,
        quickLinks: menuData,
    };
};

export default MyApp;