import { Site, TMenu } from '@app/model';
import { createContext, ReactNode, useState } from 'react';
import { SET_IS_PREVIEW } from './constant';

export type AppContextType = {
    generalData: Site;
    menuData: TMenu[];
    metaData?: {
        quickLinks?: TMenu[];
    };
    isPreviewSite: boolean;
    // eslint-disable-next-line no-unused-vars
    dispatch?: (arg0: { type: any; payload?: any }) => void;
}

export const AppContext = createContext<AppContextType>({
    generalData: {} as Site,
    menuData: [],
    isPreviewSite: false
});

export const AppProvider = ({ children, value }: { children: ReactNode; value: any }) => {
    const [isPreviewSite, setIsPreviewSite] = useState(false);

    const dispatch = ({ type, payload }: { type: string; payload?: any }) => {
        switch (type) {
            case SET_IS_PREVIEW:
                setIsPreviewSite(payload || false);
                break;
            default:
                break;
        }
    };

    const appContextValue = { ...value, isPreviewSite, dispatch } as AppContextType;
    return (<AppContext.Provider value={appContextValue}>
        {children}
    </AppContext.Provider>);

};

export const { Consumer: AppConsumer } = AppContext;


