export const animateCSS = (elementQuery: string, animation: string, prefix = 'animate__') =>
    // We create a Promise and return it
    new Promise((resolve) => {
        const animationName = `${prefix}${animation}`;
        const node = document.querySelector(elementQuery);

        if (node) {
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            const handleAnimationEnd = (event: any) => {
                event.stopPropagation();
                if (node) node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            };

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        }
    });