export const SEO_URL = process.env.NEXT_PUBLIC_SEO_URL; 
export const API_URL = process.env.NEXT_PUBLIC_API_URL; 
export const APP_URLS: string = process.env.NEXT_PUBLIC_APP_URLS || "[]"; 
export const ASSETS_URL = `${API_URL}/assets`;

export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME;
export const APP_DIRECTUS_KEY = process.env.NEXT_PUBLIC_APP_DIRECTUS_KEY || '';
export const APP_DESCRIPTION = process.env.NEXT_PUBLIC_APP_DESCRIPTION;
export const APP_KEYWORDS = process.env.NEXT_PUBLIC_APP_KEYWORDS;

export const TWITTER_HANDLE = process.env.NEXT_PUBLIC_TWITTER_HANDLE;
export const TWITTER_SITE = process.env.NEXT_PUBLIC_TWITTER_SITE;

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;

export const isProductionEnv = process.env.NODE_ENV === 'production';

export const DefaultPageImageId = '5c529ec6-970e-4a99-9bb3-c8f1aa303d3e';
