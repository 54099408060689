import { ASSETS_URL } from '@app/constants';

export const MediaHelper = {
    getUrl: (photo_id: string) => `${ASSETS_URL}/${photo_id}`,

    getYouTubeVideoId: (url: string) => {
        const regExp =
            /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

        const match = url.match(regExp);
        return match && match[7].length == 11 ? match[7] : undefined;
    },

    getYouTubeVideoScreenShot: (url: string) => {
        const regExp =
            /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const default_video_screenshot = "";
        const match = url.match(regExp);
        return match && match[7].length == 11
            ? "https://img.youtube.com/vi/" + match[7] + "/hqdefault.jpg"
            : default_video_screenshot;
    },
};
