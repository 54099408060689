import { APP_URL, BaseAPIURL } from '@app/constants';
import axios from 'axios';

const axiosClient = (baseURL: string = BaseAPIURL) => axios.create({ baseURL });
axiosClient().interceptors.response.use(
    (res) => res.data,
    (err) => Promise.reject(err)
);

type TPreview = {
    isEnabled: boolean;
};

export const QUERY_API = {
    preview: {
        checkStatus: async <T = TPreview>() =>
            axiosClient().get<T>(APP_URL.previewStatus),
        action: async <T = TPreview>(path: string) =>
            axiosClient().get<T>(path),
    },
};
