const getPDF = (text: string) => {
    const regex = /\[PDF\]\(([a-zA-Z0-9._~\-\/:]*)\)/gi;
    let result;
    let returnResult = text;

    try {
        while ((result = regex.exec(text)) !== null) {
            const [parsedPdfText, pdfLink] = result;
        
            returnResult = returnResult.replace(
                parsedPdfText,
                `<iframe class="ui-page-media ui-page-media-pdf rounded shadow" src="${pdfLink}"></iframe>`,
            );
        }
    } catch (e) {
        return returnResult;
    }

    return returnResult;
};
export const OutputHtmlHelper = (text: string) => {
    const parserList = [getPDF];
    const parsedText = parserList.reduce((currentText, currentParser) => {
        return currentParser(currentText);
    }, text);

    return <div dangerouslySetInnerHTML={{ __html: parsedText }} />;
};
