import { PreviewMode } from '@app/components/common/preview';
import { FooterLayout, HeaderLayout } from '@app/components/layout';
import { CMSBaseURL, LocalStorageKey, PreviewModeURL } from '@app/constants';
import { SET_IS_PREVIEW } from '@app/context/constant';
import { useAppData } from '@app/hooks';
import { Site, TMenu } from '@app/model';
import classnames from 'classnames';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';

export type MainLayout = {
    header?: React.ReactNode;
    footer?: React.ReactNode;
    layoutProps?: {
        wrapperClassName?: string;
        menuData?: TMenu[];
        generalData?: Site;
        hideFooter?: boolean;
    };
    CMSInfo?: {
        slug?: string;
        id?: string;
    };
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>;

export const MainLayout = ({
    header,
    footer,
    className,
    children,
    layoutProps: { wrapperClassName, menuData, generalData, hideFooter } = {},
    CMSInfo,
    ...rest
}: MainLayout) => {
    const [isPreviewEnable, setIsPreviewEnable] = useState(false);
    const [isPreviewEnableDefault, setIsPreviewEnableDefault] = useState(true);
    const cmsEditURL = `${CMSBaseURL}/${CMSInfo?.slug}/${CMSInfo?.id}`;
    const { dispatch } = useAppData();

    useEffect(() => {
        const previewMode = localStorage.getItem(LocalStorageKey.preview);
        if (previewMode !== null) {
            setIsPreviewEnableDefault(previewMode === 'true' ? true : false);
        }
    }, []);

    useEffect(() => {
        const { origin } = window.location;
        if (PreviewModeURL?.some((path) => path === origin)) {
            setIsPreviewEnable(true);
            dispatch && dispatch({ type: SET_IS_PREVIEW, payload: true });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div
            className={classnames('content-wrapper main-layout position-relative', wrapperClassName)}
            {...rest}
        >
            {header || <HeaderLayout {...{ menuData }} />}
            <main className={classnames('wrapper bg-light', className)}>
                {children}
            </main>
            {(footer) || ((generalData && !hideFooter) && <FooterLayout {...{ generalData }} />)}
            {isPreviewEnable &&
                <PreviewMode defaultEnable={isPreviewEnableDefault} >
                    {CMSInfo && <Link href={cmsEditURL}>
                        <a target='_blank' className='cms-edit-btn btn btn-sm btn-gradient gradient-7 rounded-pill'>Edit on CMS</a>
                    </Link>}
                </PreviewMode>
            }
        </div>
    );
};
