import { ASSETS_URL } from '@app/constants';
import { validate as validateUUID } from "uuid";

export { StringHelper } from './string';
export { MediaHelper } from './media';
export { OutputHtmlHelper } from './html';
export * from './date';

export function getMediaUrl(domainUrl: string, mediaId: string) {
    if (!mediaId) {
        return 'image/placeholder.jpg';
    }

    return `${domainUrl}/assets/${mediaId}`;
}

export const getAssetFilePath = (fileId: string) => {
    if (!validateUUID(fileId)) {
        throw new Error('File id is not valid!!');
    }

    return `${ASSETS_URL}/${fileId}`;
};

export { gtagUtils } from '../helpers/gtag';
export {
    animateCSS
} from './animation';
export * from './color';
